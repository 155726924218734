/* 
@import url(./css/bootstrap.min.css);
@import url(./css/demo.css);
@import url(./css/paper-dashboard.css);
@import url(./css/themify-icons.css); */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1e2321;
    -webkit-transition: 0.4s;
  
    box-shadow: 2px 6px 25px #1e2321;
    transform: translate(0px, 0px);
    transition: 0.6s ease transform, 0.6s box-shadow;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #50bfe6;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #50bfe6;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .btn-align{
    margin: 12px 12px 0;
    padding: 5px 20px;
  }
  .report-btn-active{
    margin: 4px 12px 6px;
    padding: 5px 20px;
    background-color: #50bfe6;
    border-color: #50bfe6;
    color: #ffffff;
  }
  .report-btn-inactive{
    margin: 4px 12px 6px;
    padding: 5px 20px;
    background-color: #c9302c;
    border-color: #c9302c;
    color: #ffffff;
  }
  .report-btn-active:active, .report-btn-active:hover{
    margin: 4px 12px 6px;
    padding: 5px 20px;
    background-color: #50bfe6 !important;
    border-color: #50bfe6 !important;
    color: #ffffff;
  }
  .report-btn-inactive:active, .report-btn-inactive:hover{
    margin: 4px 12px 6px;
    padding: 5px 20px;
    background-color: #c9302c !important;
    border-color: #c9302c !important;
    color: #ffffff;
  }
  .report-btn-diabled{
    margin: 4px 12px 6px;
    padding: 5px 20px;
    background-color: #c9302c;
    border-color: #c9302c;
    color: #000;
  }
 tr td .height-fix{
    height: 60px;
    overflow-y: scroll;
  }

  /* SubscriptionDetails.css */

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-item .sl-loader {
  margin-top: 25em !important;
}

.main-wrap>.content {
  padding: 30px 15px;
  min-height: calc(100vh - 123px);
}

/* .main-wrap>.content .card{
  padding: 14px 14px;
} */
.card .content {
  padding: 15px 15px 10px;
}
.card .header {
    padding: 20px 20px 0;
}
.inner-card{
  background-color: #fffcf5;
  min-height: 300px;
}
p.user-details{
width:100%;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 5;
-webkit-box-orient: vertical;
}
h5.user-details{
  width:100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  }
.photos-section img{
  width: 100%;
  min-height: 180px;
  object-fit: cover;
  display: block;
  border-radius: 20px;
}
.photos-section .col-lg-4 {
  margin-bottom: 30px;
}
.video-details video {
  width: 100%;
}
.textarea-height textarea{
  height: 200px;
}

.description-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 600px !important;
}
.react-datepicker-wrapper{
  display: inline !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 30px !important;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 1rem !important;
  box-sizing: content-box;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.btn-primary.disabled {
  background-color: #337ab7 !important;
  border-color: #2e6da4 !important;
}
.react-datepicker {
  font-size: 1.2rem !important;
}
.react-datepicker__header {
  padding-top: 1.2rem !important;
}
.react-datepicker__month {
  margin: 0.8rem 1.2rem !important;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 2.9rem !important;
  line-height: 2.9rem !important;
  margin: 0.166rem;
}
.react-datepicker__current-month {
  font-size: 1.4rem !important;
}
.btn-warning.disabled {
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
}